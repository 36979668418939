$primary: #454545;
$secondary: #EC407A; //#d41872;
$secondary-light: #F48FB1; //#ff88be;
$danger-color: #a20a0a;
$spotify-color: #1DB954;


/*<editor-fold desc="General & Nav">*/
* {
  font-family: 'Raleway', serif !important;
}

html, body, .modal {
  min-width: 300px !important;
}

html {
  min-height: 100vh;
}

body {
  padding-bottom: 4rem;
  position: relative;
  min-height: 100vh;
}

footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 4rem;
}

html::-webkit-scrollbar, body::-webkit-scrollbar, .scrollable::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}

html::-webkit-scrollbar-track, body::-webkit-scrollbar-track, .scrollable::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

html::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb, .scrollable::-webkit-scrollbar-thumb {
  background-color: #d1cfcf;
}

html::-webkit-scrollbar-thumb:hover, body::-webkit-scrollbar-thumb:hover, .scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #9e9d9d
}

.scrollable-sm::-webkit-scrollbar {
  width: 0.2rem;
  height: 0.2rem;
}

.scrollable-sm::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollable-sm::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
}

.scrollable-sm::-webkit-scrollbar-thumb:hover {
  background-color: #e7e7e7;
}

.scrollable {
  scroll-behavior: smooth;
}

a[href^=tel], a[href^=mailto] {
  color: inherit !important;
  text-decoration: none !important;
}

.z-flex-centered {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.z-flex-between-centered {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.dom-init{
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 999;
}

//<editor-fold desc="Navbar">
.navbar-scroll .fa-bars, .navbar-scroll .nav-link {
  color: inherit !important;
}

.navbar .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  color: #fff;
}

#mainNav.navbar-scrolled {
  background-color: $primary;
}

#mainNav #navLogo-text, #mainNav #sidenavMenuTrigger {
  color: #fff;
}


.navbar.navbar-scroll.navbar-scrolled {
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
}

.sidenav-backdrop {
  background-color: hsla(0, 0%, 0%, 0.6)
}

.sidenav-link.active {
  color: $secondary !important;
  font-weight: bold;
}

#footerMenu a:hover, #footerMenu a.active {
  color: $secondary !important;
}

#footerMenu a:hover {
  text-decoration: underline;
  color: $secondary !important;
}

//</editor-fold>

//<editor-fold desc="Buttons">
@media (max-width: 767px) {
  .btn-mobile {
    width: 100% !important;
    border-radius: 0;
    margin: 0;
  }
}

.btn {
  border-radius: 0 !important;
}

.btn-sm {
  font-size: 0.6rem;
  height: 25px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btt-btn {
  position: fixed;
  width: auto !important;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px !important;
  line-height: 60px !important;
  background: rgba(0, 0, 0, 0.1);
}

.btt-btn i {
  color: rgba(0, 0, 0, 0.4);
}

.btt-btn:hover {
  background: rgba(0, 0, 0, 0.2);
}

.dc-btn-general .zhidden {
  display: none !important;
}

.dc-btn-general.zclicked .zhidden {
  display: inline !important;
}

.dc-btn.zclicked {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  width: auto !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.dc-btn {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  display: none !important;
}

.dc-btn.zclicked {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  width: auto !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.dc-btn.ztouched {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.dc-btn .zhidden {
  display: none;
}

.dc-btn.zclicked .zhidden {
  display: inline;
}


//</editor-fold>

//<editor-fold desc="Colors">
.gradient-primary {
  background: #3D3D3DFF;
  background: -webkit-linear-gradient(to right, transparent, rgba(61, 61, 61, 1));
  background: -o-linear-gradient(to right, transparent, rgba(61, 61, 61, 1));
  background: -moz-linear-gradient(to right, transparent, rgba(61, 61, 61, 1));
  background: linear-gradient(to right, transparent, rgba(61, 61, 61, 1))
}

.gradient-secondary {
  background: $secondary;
  background: -webkit-linear-gradient(left, #a445b2, $secondary, #fa4299);
  background: -o-linear-gradient(left, #a445b2, $secondary, #fa4299);
  background: -moz-linear-gradient(left, #a445b2, $secondary, #fa4299);
  background: linear-gradient(left, #a445b2, $secondary, #fa4299);
}

.bg-spotify {
  background-color: $spotify-color !important;
  color: white !important;
}

.text-spotify {
  color: $spotify-color !important;
}

.text-secondary:disabled {
  color: #757575 !important;
}

//</editor-fold>

//<editor-fold desc="Hover And Click">
.hover-secondary:hover, .hover-secondary:active {
  background-color: $secondary !important;
  color: $primary !important;
}

.hover-danger:hover, .hover-danger:active {
  background-color: #dc4c64 !important;
  color: #fff !important;
}

.hover-success:hover, .hover-success:active {
  background-color: #14a44d !important;
  color: #fff !important;
}

.hover-warning:hover, .hover-warning:active {
  background-color: #e4a11b !important;
  color: #fff !important;
}

.click-bold:hover {
  text-decoration: underline;
  font-weight: bold;
}

.click-bold:hover svg {
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

.click-warning:hover {
  color: #e4a11b !important;
  text-decoration: underline;
}

.click-danger:hover {
  color: #dc4c64 !important;
  text-decoration: underline;
}

.click-info:hover {
  color: #54b4d3 !important;
  text-decoration: underline;
}

.click-primary:hover {
  color: $primary !important;
  text-decoration: underline;
}

.click-light:hover {
  color: #fbfbfb !important;
  text-decoration: underline;
}

.click-success:hover {
  color: #14a44d !important;
  text-decoration: underline;
}

//</editor-fold>

//<editor-fold desc="Form inputs">
.form-outline.form-outline-secondary .form-control:focus ~ .form-notch .form-notch-leading {
  border-color: $secondary;
  -webkit-box-shadow: -1px 0 0 0 $secondary, 0 1px 0 0 $secondary, 0 -1px 0 0 $secondary;
  box-shadow: -1px 0 0 0 $secondary, 0 1px 0 0 $secondary, 0 -1px 0 0 $secondary;
}

.form-outline.form-outline-secondary .form-control:focus ~ .form-notch .form-notch-trailing {
  border-color: $secondary;
  -webkit-box-shadow: 1px 0 0 0 $secondary, 0 -1px 0 0 $secondary, 0 1px 0 0 $secondary;
  box-shadow: 1px 0 0 0 $secondary, 0 -1px 0 0 $secondary, 0 1px 0 0 $secondary;
}

.form-outline.form-outline-secondary .form-control:focus ~ .form-notch .form-notch-middle {
  border-color: $secondary;
  -webkit-box-shadow: 0 1px 0 0 $secondary;
  box-shadow: 0 1px 0 0 $secondary;
  border-top: 1px solid transparent;
}

.form-check-input[type=checkbox]:checked, .form-check-input[type=checkbox]:checked:focus {
  background-image: none;
  background-color: $secondary-light;
}

.form-switch .form-check-input {
  height: 0.6rem;
}

.form-switch .form-check-input:after, .form-switch .form-check-input:focus:after {
  width: 1rem;
  height: 1rem;
  background: grey;
}

.form-switch .form-check-input:before {
  content: none;
}

.form-switch .form-check-input:checked[type=checkbox]:after, .form-switch .form-check-input:checked[type=checkbox]:focus:after {
  width: 1rem;
  height: 1rem;
  background-color: $secondary;
}

.form-check-lg[type=checkbox] {
  border-radius: 0 !important;;
  margin-top: 0 !important;
  margin-right: 0 !important;
}

.form-check-lg[type=checkbox] {
  width: 1.4rem;
  height: 1.4rem;
  border-color: #454545 !important;
}

.form-check-lg[type=checkbox]:checked:focus, .form-check-lg[type=checkbox]:checked {
  border-color: #f48fb1 !important;
}

.form-check-lg[type=checkbox]:before {
  content: none;
}

.form-check-lg[type=checkbox]:checked:after {
  width: 0.5rem;
  height: 1rem;
  margin-left: 0.35rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

//</editor-fold>

//<editor-fold desc="Other">
table th, table td {
  padding-left: 0.5rem !important;
}

.border-thin {
  border-width: 0.044rem;
}

.pointer {
  cursor: pointer !important;
}

.shadow-inner {
  -webkit-box-shadow: inset 0 0.063rem 1.188rem 0 rgba(0, 0, 0, .4) !important;
  box-shadow: inset 0 0.063rem 1.188rem 0 rgba(0, 0, 0, .4) !important
}

.ztext-small {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-small {
  font-size: 0.85rem;
}

.d-md-only {
  display: none;
}

.znormalize-text {
  font-family: "Calibri", serif !important;
}
.hr-on-top-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.hr-on-top {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.hr-on-top.zright {
  left:inherit;
  right: 0;
}

.hr-light {
  background-image: linear-gradient(90deg, #d6d6d6, transparent) !important;
  height: 1px !important;
}
.hr-blurry {
  background-image: linear-gradient(90deg, transparent, #ffffff, transparent) !important;
  height: 2px !important;
}
//</editor-fold>

//<editor-fold desc="Spotify Classes">
.img-fluid.qr-code {
  max-width: 50% !important;
  max-height: 50% !important;
  width: auto !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

#ts-sp-vpt-searchTrack-input:focus {
  border-color: #EC407A !important;
  -webkit-box-shadow: inset 0 .063rem 1.188rem 0 rgba(0, 0, 0, .2) !important;
  box-shadow: inset 0 .063rem 1.188rem 0 rgba(0, 0, 0, .2) !important;
}

.sp-track.zactive, .sp-searchTrack.zactive {
  background-color: rgba(236, 247, 255, 1) !important;
}

.sp-searchTrack.zdummy::before {
  content: none;
}

#ts-sp-vpt-m.has-hover .sp-searchTrack:hover .dc-btn, #ts-sp-vpt-m.has-hover .sp-track:hover .dc-btn {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  width: auto !important;
}

.p-spPlaylist.zactivated *:not(.opIgnore) {
  opacity: 0.6
}

//</editor-fold>
/*</editor-fold>*/

//<editor-fold desc="Font">

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCFPrEHJA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  font-display: swap;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCMPrEHJA.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  font-display: swap;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCHPrEHJA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  font-display: swap;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCGPrEHJA.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCIPrE.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

//</editor-fold>

/*<editor-fold desc="BG">*/

/*<editor-fold desc="BG-Dotted-Black">*/
.bg-dotted-3 {
  background: url(/build/images/bg/dotblack.webp);
  background-size: 300px;
}

.bg-dotted-4 {
  background: url(/build/images/bg/dotblack.webp);
  background-size: 400px;
}

.bg-dotted-5 {
  background: url(/build/images/bg/dotblack.webp);
  background-size: 500px;
}

.bg-dotted-6 {
  background: url(/build/images/bg/dotblack.webp);
  background-size: 600px;
}

.bg-dotted-7 {
  background: url(/build/images/bg/dotblack.webp);
  background-size: 700px;
}

.bg-dotted-8 {
  background: url(/build/images/bg/dotblack.webp);
  background-size: 800px;
}

/*</editor-fold>*/

/*<editor-fold desc="BG-Dotted-White">*/
.bg-dotted-white-3 {
  background: url(/build/images/bg/dotwhite.webp);
  background-size: 300px;
}

.bg-dotted-white-4 {
  background: url(/build/images/bg/dotwhite.webp);
  background-size: 400px;
}

.bg-dotted-white-5 {
  background: url(/build/images/bg/dotwhite.webp);
  background-size: 500px;
}

.bg-dotted-white-6 {
  background: url(/build/images/bg/dotwhite.webp);
  background-size: 600px;
}

.bg-dotted-white-7 {
  background: url(/build/images/bg/dotwhite.webp);
  background-size: 700px;
}

.bg-dotted-white-8 {
  background: url(/build/images/bg/dotwhite.webp);
  background-size: 800px;
}

/*</editor-fold>*/

/*<editor-fold desc="BG-Container">*/
.bg-container, .bg-container-double {
  height: auto !important;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-container .mask:not(.dom-init), .bg-container-double .mask:not(.dom-init), .bg-container .bg-wrapper, .bg-container-double .bg-wrapper {
  height: auto !important;
  min-height: 100vh;
  position: relative;
}

.bg-container.st, .bg-container-double.st {
  background-position: left;
}

.bg-container.ed, .bg-container-double.ed {
  background-position: right;
}

/*</editor-fold>*/

/*<editor-fold desc="BG-Dark-Gradient">*/
.bg-dark-1 {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.bg-dark-2 {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.bg-dark-3 {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.bg-dark-4 {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.bg-dark-5 {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.bg-dark-6 {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.bg-dark-7 {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.bg-dark-8 {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.bg-dark-9 {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.bg-dark {
  background-color: hsl(0, 0%, 97%);
}

/*</editor-fold>*/

/*<editor-fold desc="BG-White-Gradient">*/
.bg-white-1 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.bg-white-2 {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.bg-white-3 {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.bg-white-4 {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.bg-white-5 {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.bg-white-6 {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.bg-white-7 {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.bg-white-8 {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.bg-white-9 {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

/*</editor-fold>*/

/*<editor-fold desc="BG-First-Group">*/
.bg-first-1 {
  background-image: url(/build/images/bg/first/bg1.png);
}
.bg-first-2 {
  background-image: url(/build/images/bg/first/bg2.png);
}
.bg-first-3 {
  background-image: url(/build/images/bg/first/bg3.png);
}
.bg-first-4 {
  background-image: url(/build/images/bg/first/bg4.png);
}

/*</editor-fold>*/

/*</editor-fold>*/

/*<editor-fold desc="Sizes">*/
.mn-1 {
  margin-left: -025rem !important;
  margin-right: -025rem !important;
}

.mn-2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.mn-3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.mn-4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.mn-5 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

/*</editor-fold>*/

/*<editor-fold desc="Small">*/
@media (min-width: 576px) {

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .mn-sm-1 {
    margin-left: -025rem !important;
    margin-right: -025rem !important;
  }

  .mn-sm-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .mn-sm-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mn-sm-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mn-sm-5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
}

/*</editor-fold>*/

/*<editor-fold desc="Medium">*/
@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .mn-md-1 {
    margin-left: -025rem !important;
    margin-right: -025rem !important;
  }

  .mn-md-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .mn-md-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mn-md-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mn-md-5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .bg-container, .bg-container-double {
    background-position: 50% !important;
  }

  .bg-container .bg-md-wrapper, .bg-container-double .bg-md-wrapper {
    height: auto !important;
    min-height: 100vh;
    position: relative;
  }
  .bg-container .mask:not(.no-blur):not(.dom-init) {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  .d-md-only {
    display: inline;
  }

  .form-check-lg[type=checkbox] {
    width: 1.25rem;
    height: 1.25rem;
  }

  .form-check-lg[type=checkbox]:checked:after {
    margin-left: 0.25rem;
    width: 0.375rem;
    height: 0.8125rem;
  }
}

/*</editor-fold>*/

/*<editor-fold desc="Large">*/
@media (min-width: 992px) {

  .w-l-25 {
    width: 25% !important;
  }

  .w-l-50 {
    width: 50% !important;
  }

  .w-l-75 {
    width: 75% !important;
  }

  .w-l-100 {
    width: 100% !important;
  }

  .mn-lg-1 {
    margin-left: -025rem !important;
    margin-right: -025rem !important;
  }

  .mn-lg-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .mn-lg-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mn-lg-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mn-lg-5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .modal-full-height {
    height: 100%;
    right: 0;
    overflow-y: auto;
    position: absolute;
    margin: 0;
    width: 100%;
    max-width: 500px;
  }

  .modal-full-height .modal-content {
    min-height: 100%;
    border-radius: 0;
  }

  #ts-sp-ap-mainContainer, #ts-sp-p-mainContainer {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  #ts-sp-ap-mainContainer .sp-sce, #ts-sp-p-mainContainer .sp-sce {
    display: none !important;
  }

  #ts-sp-ap-mainContainer.zexpanded .sp-sce, #ts-sp-p-mainContainer.zexpanded .sp-sce {
    display: inline !important;
  }

  #ts-sp-ap-mainContainer.zexpanded .sp-sce.d-flex, #ts-sp-p-mainContainer.zexpanded .sp-sce.d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
}

/*</editor-fold>*/

/*<editor-fold desc="XL">*/
@media (min-width: 1200px) {

  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .mn-xl-1 {
    margin-left: -025rem !important;
    margin-right: -025rem !important;
  }

  .mn-xl-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .mn-xl-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mn-xl-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mn-xl-5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
}

/*</editor-fold>*/

/*<editor-fold desc="XXL">*/
@media (min-width: 1400px) {

  .w-xxl-25 {
    width: 25% !important;
  }

  .w-xxl-50 {
    width: 50% !important;
  }

  .w-xxl-75 {
    width: 75% !important;
  }

  .w-xxl-100 {
    width: 100% !important;
  }

  .mn-xxl-1 {
    margin-left: -025rem !important;
    margin-right: -025rem !important;
  }

  .mn-xxl-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .mn-xxl-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mn-xxl-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mn-xxl-5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  #ts-sp-ap-mainContainer .sp-sce, #ts-sp-p-mainContainer .sp-sce {
    display: inline !important;
  }

  #ts-sp-ap-mainContainer .sp-sce.d-flex, #ts-sp-p-mainContainer .sp-sce.d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  #ts-sp-ap-mainContainer.zexpanded .sp-sce, #ts-sp-p-mainContainer.zexpanded .sp-sce {
    display: inline !important;
  }

  #ts-sp-ap-mainContainer.zexpanded .sp-sce.d-flex, #ts-sp-p-mainContainer.zexpanded .sp-sce.d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
}

/*</editor-fold>*/

//<editor-fold desc="DataTable">
table.dataTable .search-filter {
  margin: 0 !important;
  padding: .25rem 0 !important;
  font-size: .875rem !important;
  height: calc(1.5em + .5rem + 0.125rem) !important;
  border: none !important;
  font-style: italic !important;
  border-radius: 0 !important;
}

table.dataTable .search-filter:focus {
  color: #495057;
  background-color: #fff;
  border-bottom: 1px solid $secondary !important;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

table.dataTable .dataTables_length {
  display: none;
}
table.dataTable th{
  text-wrap: nowrap!important;
}

table.dataTable thead > tr > td.sorting_asc:before, table.dataTable thead > tr > td.sorting_desc:after, table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_desc:after {
  color: $secondary;
}

table.dataTable thead > tr > td.sorting_asc:before, table.dataTable thead > tr > td.sorting_desc:after, table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_desc:after {
  color: $secondary;
}

.dataTables_processing > div:last-child > div {
  width: 7px !important;;
  height: 7px !important;
  background: $secondary !important;;
}

.dataTables_processing {
  top: inherit !important;
  margin-top: 0 !important;
  bottom: -30px;
  height: 30px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

//</editor-fold>

//<editor-fold desc="Sphere Animation">
.sp-main-wrapper {
  display: flex;
  position: absolute;
  transform-style: preserve-3d;
  perspective: 400px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
}

.sp-sphere-wrapper {
  transform-style: preserve-3d;
  width: 300px;
  height: 300px;
  position: relative;
  animation: sprotate3d 10s linear infinite;
}

.sp-plane {
  position: absolute;
  transform-style: preserve-3d;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.sp-spoke {
  transform-origin: 0 0;
  transform-style: preserve-3d;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 150px;
  width: 0;
}

.sp-dot {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #ffffff;
  left: -3px;
  top: 100%;
  transform: rotateX(90deg);
}

.sp-spoke.sp-spoke-1 {
  transform: rotateZ(10deg);
}

.sp-spoke.sp-spoke-2 {
  transform: rotateZ(20deg);
}

.sp-spoke.sp-spoke-3 {
  transform: rotateZ(30deg);
}

.sp-spoke.sp-spoke-4 {
  transform: rotateZ(40deg);
}

.sp-spoke.sp-spoke-5 {
  transform: rotateZ(50deg);
}

.sp-spoke.sp-spoke-6 {
  transform: rotateZ(60deg);
}

.sp-spoke.sp-spoke-7 {
  transform: rotateZ(70deg);
}

.sp-spoke.sp-spoke-8 {
  transform: rotateZ(80deg);
}

.sp-spoke.sp-spoke-9 {
  transform: rotateZ(90deg);
}

.sp-spoke.sp-spoke-10 {
  transform: rotateZ(100deg);
}

.sp-spoke.sp-spoke-11 {
  transform: rotateZ(110deg);
}

.sp-spoke.sp-spoke-12 {
  transform: rotateZ(120deg);
}

.sp-spoke.sp-spoke-13 {
  transform: rotateZ(130deg);
}

.sp-spoke.sp-spoke-14 {
  transform: rotateZ(140deg);
}

.sp-spoke.sp-spoke-15 {
  transform: rotateZ(150deg);
}

.sp-spoke.sp-spoke-16 {
  transform: rotateZ(160deg);
}

.sp-spoke.sp-spoke-17 {
  transform: rotateZ(170deg);
}

.sp-spoke.sp-spoke-18 {
  transform: rotateZ(180deg);
}

.sp-spoke.sp-spoke-19 {
  transform: rotateZ(190deg);
}

.sp-spoke.sp-spoke-20 {
  transform: rotateZ(200deg);
}

.sp-spoke.sp-spoke-21 {
  transform: rotateZ(210deg);
}

.sp-spoke.sp-spoke-22 {
  transform: rotateZ(220deg);
}

.sp-spoke.sp-spoke-23 {
  transform: rotateZ(230deg);
}

.sp-spoke.sp-spoke-24 {
  transform: rotateZ(240deg);
}

.sp-spoke.sp-spoke-25 {
  transform: rotateZ(250deg);
}

.sp-spoke.sp-spoke-26 {
  transform: rotateZ(260deg);
}

.sp-spoke.sp-spoke-27 {
  transform: rotateZ(270deg);
}

.sp-spoke.sp-spoke-28 {
  transform: rotateZ(280deg);
}

.sp-spoke.sp-spoke-29 {
  transform: rotateZ(290deg);
}

.sp-spoke.sp-spoke-30 {
  transform: rotateZ(300deg);
}

.sp-spoke.sp-spoke-31 {
  transform: rotateZ(310deg);
}

.sp-spoke.sp-spoke-32 {
  transform: rotateZ(320deg);
}

.sp-spoke.sp-spoke-33 {
  transform: rotateZ(330deg);
}

.sp-spoke.sp-spoke-34 {
  transform: rotateZ(340deg);
}

.sp-spoke.sp-spoke-35 {
  transform: rotateZ(350deg);
}

.sp-plane.sp-plane-1 {
  transform: rotateY(15deg);
}

.sp-plane.sp-plane-2 {
  transform: rotateY(30deg);
}

.sp-plane.sp-plane-3 {
  transform: rotateY(45deg);
}

.sp-plane.sp-plane-4 {
  transform: rotateY(60deg);
}

.sp-plane.sp-plane-5 {
  transform: rotateY(75deg);
}

.sp-plane.sp-plane-6 {
  transform: rotateY(90deg);
}

.sp-plane.sp-plane-7 {
  transform: rotateY(105deg);
}

.sp-plane.sp-plane-8 {
  transform: rotateY(120deg);
}

.sp-plane.sp-plane-9 {
  transform: rotateY(135deg);
}

.sp-plane.sp-plane-10 {
  transform: rotateY(150deg);
}

.sp-plane.sp-plane-11 {
  transform: rotateY(165deg);
}

.sp-spoke-0 .sp-dot,
.sp-spoke-36 .sp-dot {
  animation: sppulsate 0.5s infinite 0s alternate both;
  background-color: #41ec40;
}

.sp-spoke-1 .sp-dot,
.sp-spoke-35 .sp-dot {
  animation: sppulsate 0.5s infinite 0.05555556s alternate both;
  background-color: #85f276;
}

.sp-spoke-2 .sp-dot,
.sp-spoke-34 .sp-dot {
  animation: sppulsate 0.5s infinite 0.11111111s alternate both;
  background-color: #b3f8a4;
}

.sp-spoke-3 .sp-dot,
.sp-spoke-33 .sp-dot {
  animation: sppulsate 0.5s infinite 0.16666667s alternate both;
  background-color: #dbfcd2;
}

.sp-spoke-4 .sp-dot,
.sp-spoke-32 .sp-dot {
  animation: sppulsate 0.5s infinite 0.22222222s alternate both;
  background-color: #ffffff;
}

.sp-spoke-5 .sp-dot,
.sp-spoke-31 .sp-dot {
  animation: sppulsate 0.5s infinite 0.27777778s alternate both;
  background-color: #ffdce3;
}

.sp-spoke-6 .sp-dot,
.sp-spoke-30 .sp-dot {
  animation: sppulsate 0.5s infinite 0.33333333s alternate both;
  background-color: #ffb9c7;
}

.sp-spoke-7 .sp-dot,
.sp-spoke-29 .sp-dot {
  animation: sppulsate 0.5s infinite 0.38888889s alternate both;
  background-color: #fb95ad;
}

.sp-spoke-8 .sp-dot,
.sp-spoke-28 .sp-dot {
  animation: sppulsate 0.5s infinite 0.44444444s alternate both;
  background-color: #f56f93;
}

.sp-spoke-9 .sp-dot,
.sp-spoke-27 .sp-dot {
  animation: sppulsate 0.5s infinite 0.5s alternate both;
  background-color: #ec407a;
}

.sp-spoke-10 .sp-dot,
.sp-spoke-26 .sp-dot {
  animation: sppulsate 0.5s infinite 0.55555556s alternate both;
  background-color: #f56f93;
}

.sp-spoke-11 .sp-dot,
.sp-spoke-25 .sp-dot {
  animation: sppulsate 0.5s infinite 0.61111111s alternate both;
  background-color: #fb95ad;
}

.sp-spoke-12 .sp-dot,
.sp-spoke-24 .sp-dot {
  animation: sppulsate 0.5s infinite 0.66666667s alternate both;
  background-color: #ffb9c7;
}

.sp-spoke-13 .sp-dot,
.sp-spoke-23 .sp-dot {
  animation: sppulsate 0.5s infinite 0.72222222s alternate both;
  background-color: #ffdce3;
}

.sp-spoke-14 .sp-dot,
.sp-spoke-22 .sp-dot {
  animation: sppulsate 0.5s infinite 0.77777778s alternate both;
  background-color: #ffffff;
}

.sp-spoke-15 .sp-dot,
.sp-spoke-21 .sp-dot {
  animation: sppulsate 0.5s infinite 0.83333333s alternate both;
  background-color: #dbfcd2;
}

.sp-spoke-16 .sp-dot,
.sp-spoke-20 .sp-dot {
  animation: sppulsate 0.5s infinite 0.88888889s alternate both;
  background-color: #b3f8a4;
}

.sp-spoke-17 .sp-dot,
.sp-spoke-19 .sp-dot {
  animation: sppulsate 0.5s infinite 0.94444444s alternate both;
  background-color: #85f276;
}

.sp-spoke-18 .sp-dot,
.sp-spoke-18 .sp-dot {
  animation: sppulsate 0.5s infinite 1s alternate both;
  background-color: #41ec40;
}

@keyframes sprotate3d {
  0% {
    transform: rotate3d(1, 1, 1, 0deg);
  }
  25% {
    transform: rotate3d(1, 1, 1, 90deg);
  }
  50% {
    transform: rotate3d(1, 1, 1, 180deg);
  }
  75% {
    transform: rotate3d(1, 1, 1, 270deg);
  }
  100% {
    transform: rotate3d(1, 1, 1, 360deg);
  }
}

@keyframes sppulsate {
  0% {
    transform: rotateX(90deg) scale(0.3) translateZ(20px);
  }
  100% {
    transform: rotateX(90deg) scale(1) translateZ(0px);
  }
}

//</editor-fold>

//<editor-fold desc="Gold Text">
@keyframes gold-shine {
  0% {
    background-position: -50rem 0;
  }
  25% {
    background-position: -50rem 0;
  }
  100% {
    background-position: 30rem 0;
  }
}

@keyframes gold-flash {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.gold-text {
  word-spacing: 0.2em;
  display: inline-block;
  padding: 0;
  line-height: 1;
  white-space: nowrap;
  color: transparent;
  background-color: #ffed00;
  background-image: -o-radial-gradient(right bottom, ellipse farthest-corner, white 0%, #f0bb7a 20%, #ffd700 60%, transparent 80%), -o-radial-gradient(left top, ellipse farthest-corner, white 0%, #f0bb7a 50%, #de9945 75%, #ffd700 100%);
  background-image: radial-gradient(ellipse farthest-corner at right bottom, white 0%, #f0bb7a 20%, #ffd700 60%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, white 0%, #f0bb7a 50%, #de9945 75%, #ffd700 100%);
  background-size: 100% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  margin: 0 0 0.5rem 0;
}

.gold-text:before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  color: #ffed00;
  z-index: -1;
  text-shadow: 0 -1px 0 #f4cc9b, 0 1px 0 #a77334, 0 2px 0 #9b6b30, 0 3px 0 #90632d, 0 4px 0 #7a5426, 0 4px 2px #7a5426;
}

.gold-text_highlight {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.gold-text_highlight:after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-image: -o-linear-gradient(350deg, transparent 0%, transparent 6rem, white 11rem, transparent 11.15rem, transparent 15rem, rgba(255, 255, 255, 0.3) 20rem, transparent 25rem, transparent 27rem, rgba(255, 255, 255, 0.6) 32rem, white 33rem, rgba(255, 255, 255, 0.3) 33.15rem, transparent 38rem, transparent 40rem, rgba(255, 255, 255, 0.3) 45rem, transparent 50rem, transparent 100%);
  background-image: linear-gradient(100deg, transparent 0%, transparent 6rem, white 11rem, transparent 11.15rem, transparent 15rem, rgba(255, 255, 255, 0.3) 20rem, transparent 25rem, transparent 27rem, rgba(255, 255, 255, 0.6) 32rem, white 33rem, rgba(255, 255, 255, 0.3) 33.15rem, transparent 38rem, transparent 40rem, rgba(255, 255, 255, 0.3) 45rem, transparent 50rem, transparent 100%);
  background-clip: text;
  background-size: 60rem 100%;
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  -webkit-animation-name: gold-shine;
  animation-name: gold-shine;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.gold-text_highlight:before {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  color: transparent;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.5)), color-stop(35%, transparent), color-stop(75%, transparent), to(#de9945));
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, transparent 35%, transparent 75%, #de9945 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, transparent 35%, transparent 75%, #de9945 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-animation-name: gold-flash;
  animation-name: gold-flash;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

//</editor-fold>


//<editor-fold desc="ZLogin">
.zinput {
  font-size: 1.25rem;
  color: #555;
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 3.125rem;
  background: 0 0;
  padding: 0 0.625rem 0 5rem;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  -moz-transition: all .4s;
  transition: all .4s;
  outline: none;
  border: none;
}

.focus-zinput {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}
.focus-zinput svg{
  position: absolute;
  top: 50%;
  left: 2.3rem;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.focus-zinput::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -0.063rem;
  left: 0;
  width: 0;
  height: 0.125rem;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  -moz-transition: all .4s;
  transition: all .4s;
  background: #d41872;
  background: -webkit-linear-gradient(left, #a445b2, #d41872, #fa4299);
  background: -o-linear-gradient(left, #a445b2, #d41872, #fa4299);
  background: -moz-linear-gradient(left, #a445b2, #d41872, #fa4299);
  background: linear-gradient(left, #a445b2, #d41872, #fa4299);
}

.wrap-zinput {
  width: 100%;
  position: relative;
  border-bottom: 0.063rem solid #e6e6e6;
}

textarea:focus, input:focus {
  border-color: transparent !important;
}

.zinput:focus + .focus-zinput::before {
  width: 100%;
}

.zinput:focus + .focus-zinput svg {
  color: #d41872;
}

.zinput-btn {
  font-size: 1.125rem;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  padding: 0 1.25rem;
  min-width: 10rem;
  height: 2.625rem;
  border-radius: 1.313rem;
  position: relative;
  z-index: 1;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  -moz-transition: all .4s;
  transition: all .4s;
  outline: none !important;
  border: none;
}

.zinput-btn:hover {
  background-color: #454545 !important;
}

.zlogin .nav-link.active {
  display: none !important;
}

//</editor-fold>


/*<editor-fold desc="Splash Styles">*/
.mixdiz-splash-container {
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  padding: 2rem;
  background-color: #000000;
}

.splash {
  animation: 2s wholeLogo;
  transform-origin: center;
  width: 70%;
  max-height: 80%;
  color: white;
}

.mixdiz-splash-logo {
  transform-origin: inherit;
}

.mixdiz-splash-divider {
  animation: rotate 300ms linear 1000ms;
  animation-fill-mode: both;
  transform-origin: inherit;
}

.mixdiz-splash-text {
  transform-origin: inherit;
}

.mixdiz-splash-text_m {
  animation: slideIn 200ms ease-out 1100ms;
  animation-fill-mode: both;
}

.mixdiz-splash-text_i {
  animation: slideIn 200ms ease-out 1150ms;
  animation-fill-mode: both;
}

.mixdiz-splash-text_x {
  animation: slideIn 200ms ease-out 1200ms;
  animation-fill-mode: both;
}

.mixdiz-splash-text_d {
  animation: slideIn 200ms ease-out 1250ms;
  animation-fill-mode: both;
}

.mixdiz-splash-text_i2 {
  animation: slideIn 200ms ease-out 1300ms;
  animation-fill-mode: both;
}

.mixdiz-splash-text_z {
  animation: slideIn 200ms ease-out 1350ms;
  animation-fill-mode: both;
}

.mixdiz-splash-text_powered {
  animation: slideIn 200ms ease-out 1700ms;
  animation-fill-mode: both;
}

.mixdiz-splash-container {
  animation: 200ms ghost 2700ms;
  animation-fill-mode: forwards;
}

@keyframes wholeLogo {
  0% {
    opacity: 0;
    transform: scale(150%) translateX(43.3%);
  }
  20% {
    opacity: 1;
    transform: scale(180%) translateX(43.3%);
  }
  40% {
    transform: scale(180%) translateX(43.3%);
  }
  65% {
    transform: scale(100%) translateX(43.3%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes rotate {
  from {
    visibility: visible;
    transform: rotateX(90deg);
  }
  to {
    transform: rotateX(0);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes ghost {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

/*</editor-fold>*/